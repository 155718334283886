<template>
  <Panel
    class="theme-selector-panel"
  >
    <PanelBodyTitle class="no-top-space">
      Choose a template
    </PanelBodyTitle>
    <p>How would you like us to typeset your book? Choose from a range of themes crafted by professional designers.</p>
    <TabOptions
      v-model="theme"
      :options="themeOptions"
      class="theme-options"
    />
    <ExportThemePreview />
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import ExportThemePreview from './export-theme-preview.vue';
import TabOptions from '@reedsy/studio.home.bookshelf/components/tab-options/tab-options.vue';
import {Theme} from '@reedsy/schemas.workers.book-export';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import PanelBodyTitle from '@reedsy/studio.home.bookshelf/components/panel/panel-body-title.vue';

import REEDSY_PREVIEW from '@/assets/inlined/theme/1.svg?inline';
import CLASSIC_PREVIEW from '@/assets/inlined/theme/2.svg?inline';
import ROMANCE_PREVIEW from '@/assets/inlined/theme/3.svg?inline';

@Component({
  components: {
    Panel,
    ExportThemePreview,
    TabOptions,
    PanelBodyTitle,
  },
})
export default class ThemeSelector extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public readonly themeOptions = [
    {
      description: 'Merriweather',
      image: REEDSY_PREVIEW,
      title: 'Reedsy',
      value: Theme.Reedsy,
    },
    {
      description: 'Crimson',
      image: CLASSIC_PREVIEW,
      title: 'Classic',
      value: Theme.Classic,
    },
    {
      description: 'Crimson',
      image: ROMANCE_PREVIEW,
      title: 'Romance',
      value: Theme.Romance,
    },
  ];

  public get theme(): Theme {
    return this.$bookExportSubmission.theme;
  }

  public set theme(theme: Theme) {
    this.$bookExportSubmission.THEME(theme);
  }
}
</script>

<style lang="scss" scoped>
.theme-selector-panel {
  .theme-options {
    margin-top: $space-md;

    :deep(div.vui-tab-option[title='Reedsy']) {
      .description p {
        @include font-family($-merriweather);

        font-size: $font-size-base;
      }
    }

    :deep(div.vui-tab-option[title='Classic']),
    :deep(div.vui-tab-option[title='Romance']) {
      .description p {
        @include font-family($cover-placeholder-font);

        font-size: $font-size-lg;
      }
    }
  }

  rbe-export-theme-preview {
    margin-top: $space-base;
  }
}
</style>
