<template>
  <div class="bookshelf-app">
    <RouterView
      ref="router-view"
      v-slot="{ Component }"
    >
      <AppLoader v-if="isInitialisingApp || !Component" />
      <component
        :is="Component"
        v-else
      />
    </RouterView>
    <ModalContainer />
  </div>
</template>

<script lang="ts">
import BookshelfVue from './bookshelf-vue';
import {Component, Provide} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalContainer from '@reedsy/studio.shared/components/modals/modal-container.vue';
import {BookshelfModalsModule} from './store/modules/modals';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from './store/store-name';
import {BookshelfLayoutModule} from './store/modules/layout';
import AppLoader from '@reedsy/studio.home.bookshelf/components/app-loader/app-loader.vue';
import {first} from '@reedsy/utils.iterable';
import {BookshelfModule} from './store/modules/bookshelf';

@Component({
  components: {
    ModalContainer,
    AppLoader,
  },
})
export default class BookshelfApp extends BookshelfVue {
  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  @$lazyInjectStore(StoreName.Layout)
  public $layout: BookshelfLayoutModule;

  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @Provide
  public get tourBookUuid(): string {
    return first(this.$bookshelf.searchableBooks
      .filter((book) => !book.hiddenAt))?.uuid;
  }

  public get isInitialisingApp(): boolean {
    if (this.$route.meta?.skipPageInitialisation) return false;
    return this.$layout.isLoadingCurrentUser;
  }
}
</script>
