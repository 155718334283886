import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bookshelf-app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_ModalContainer = _resolveComponent("ModalContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RouterView, { ref: "router-view" }, {
      default: _withCtx(({ Component }) => [
        (_ctx.isInitialisingApp || !Component)
          ? (_openBlock(), _createBlock(_component_AppLoader, { key: 0 }))
          : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 1 }))
      ]),
      _: 1
    }, 512),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createVNode(_component_ModalContainer)
  ]))
}