<template>
  <Panel class="print-on-demand">
    <rbe-print-on-demand-content>
      <h1>
        Print-on-demand with Blurb
      </h1>
      <rbe-blurb-details>
        <p>
          Looking to print physical copies, or make your book available to stores near you?
          Reedsy has partnered with Blurb to help you print and distribute your book to readers around the world.
          Take one step closer to publishing and send your Reedsy PDF to Blurb.
          <a
            :href="blurbUploaderUrl"
            target="_blank"
            title="Learn more"
            class="link"
          >Learn more</a>.
        </p>

        <img
          src="@/assets/inlined/blurb.svg"
          alt="blurb logo"
          class="blurb-logo"
        >
      </rbe-blurb-details>

      <a
        :href="blurbUploaderUrl"
        class="go-to-blurb-button button button-with-icon reedsy-accented"
        target="_blank"
      >
        <span class="text">Go to Blurb</span>
        <VuiIconExternal />
      </a>
    </rbe-print-on-demand-content>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import {config} from '@reedsy/studio.shared/config';

@Component({
  components: {
    Panel,
  },
})
export default class BlurbPrintOnDemand extends BookshelfVue {
  public readonly blurbUploaderUrl = config.externalWebsites.blurbUploader.url;
}
</script>

<style lang="scss" scoped>
.print-on-demand {
  margin-top: $space-xl;

  rbe-print-on-demand-content h1 {
    font-size: $font-size-base;
    margin-bottom: $space-md;
  }

  rbe-blurb-details {
    display: flex;
    width: 100%;

    p {
      flex-grow: 1;
    }

    .blurb-logo {
      flex-shrink: 0;
      flex-grow: 0;
      height: 5rem;
      margin-left: $space-md;
    }
  }

  .go-to-blurb-button {
    margin-top: $space-md;
  }
}
</style>
