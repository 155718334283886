<template>
  <rbe-subscription-account-settings class="reedsy-accented accent-premium">
    <!-- TODO: [subscriptions]: get proper designs -->
    <p>
      <a
        v-if="$subscription.hasAnyPaidFeature"
        :href="url"
        class="button button-with-icon reedsy-accented"
        target="_blank"
      >
        <span>
          Subscription settings
        </span>
        <VuiIconExternal />
      </a>
      <button
        v-else
        class="button reedsy-accented"
        @click="$subscriptionModal.open"
      >
        Go Premium
      </button>
    </p>
  </rbe-subscription-account-settings>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {config} from '@reedsy/studio.shared/config';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';

@Component({})
export default class SubscriptionAccountSettings extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  public readonly url = config.stripe.customerPortalURL;
}
</script>
