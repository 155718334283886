import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/inlined/reedsy-account.svg'


const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, { class: "bordered auth-account-settings-banner" }, {
    footer: _withCtx(() => [
      _createElementVNode("rbe-footer", null, [
        _createElementVNode("p", null, [
          _cache[5] || (_cache[5] = _createTextVNode("\n          Your Reedsy account can be used to\n          ")),
          _createElementVNode("a", {
            href: _ctx._navigation.authDashboardUrl,
            class: "text-link",
            "data-test-id": "account-dashboard-link",
            target: "_blank",
            title: "Sign in to other Reedsy Apps"
          }, "sign in to other Reedsy Apps", 8, _hoisted_2),
          _cache[6] || (_cache[6] = _createTextVNode(".\n        "))
        ]),
        _cache[8] || (_cache[8] = _createTextVNode()),
        _createElementVNode("a", {
          href: _ctx._navigation.authDashboardUrl,
          "data-test-id": "account-dashboard-img-link",
          target: "_blank",
          title: "Reedsy account"
        }, _cache[7] || (_cache[7] = [
          _createElementVNode("img", { src: _imports_0 }, null, -1)
        ]), 8, _hoisted_3)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("rbe-account-info", null, [
        _createElementVNode("rbe-avatar-wrapper", null, [
          _createVNode(_component_Avatar, {
            "user-info": _ctx.user,
            bordered: "",
            class: "xl"
          }, null, 8, ["user-info"])
        ]),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _createElementVNode("rbe-info", null, [
          _createElementVNode("h1", null, [
            _cache[0] || (_cache[0] = _createTextVNode("\n          Logged in as ")),
            _createElementVNode("span", {
              ref: "userName",
              class: "user-name"
            }, _toDisplayString(_ctx.userName), 513)
          ]),
          _cache[1] || (_cache[1] = _createTextVNode()),
          _cache[2] || (_cache[2] = _createElementVNode("p", null, "\n          Your name, email, and password can be configured on your Reedsy account.\n        ", -1))
        ]),
        _cache[4] || (_cache[4] = _createTextVNode()),
        _createElementVNode("rbe-button-wrapper", null, [
          _createElementVNode("a", {
            href: _ctx._navigation.authAccountSettingsUrl,
            class: "button reedsy-accented",
            title: "Make changes"
          }, "\n          Make changes\n        ", 8, _hoisted_1)
        ])
      ]),
      _cache[9] || (_cache[9] = _createTextVNode())
    ]),
    _: 1
  }))
}